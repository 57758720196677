import React from "react"
import { Link } from "gatsby"
import { Container, Visible } from 'react-grid-system'
import { Disclosure } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"
import { Navigation } from "./nav/links"
import Scrollspy from 'react-scrollspy'

import Logo from '../../../icons/logo.svg'  

export default function Header() {
  
  return (
    <div className="nav-container">
      <Visible xs sm md>
        <div className="mobile-logo">
          <Logo />
        </div>
      </Visible>
    <Disclosure as="nav">
      {({ open }) => (
        <>
          <Container className="py-3 flex items-center justify-between md:block nav-wrap">
              <div className="flex items-center">
                <div className="navigation-wrapper hidden md:ml-6 md:flex">
                  <Container>
                    <Scrollspy 
                      items={ ['home', 'what', 'contact'] } 
                      currentClassName="active" 
                      className="w-full flex fixed-nav"
                    >
                      {Navigation.map((link, i) => (
                        <li className="relative" key={i}>
                          <Link
                            key={i}
                            to={link.link}
                            className="inline-flex items-center pt-1 mr-20 text-white parent"
                            activeClassName="border-indigo-500  active"
                          >
                            {link.title === 'Home' ? 
                              <Logo /> : null                            
                            }
                            {link.title}
                          </Link>
                        </li>
                      ))}
                    </Scrollspy>
                  </Container>
                </div>
              </div>
              {/* Mobile Navigation */}
              <div className="flex items-center md:hidden">
                <Disclosure.Button className="inline-flex burger-container items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-12 w-12" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-12 w-12" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
          </Container>
          <Disclosure.Panel className="md:hidden">
            <div className="pt-2 pb-3 space-y-1 mobile-menu">
              {Navigation.map((link, i) => (
                <>
                  <Link
                    key={i}
                    to={link.link}
                    className="border-t-2 block pl-3 pr-4 py-4 font-medium"
                    activeClassName="border-indigo-500  active"
                  >
                    {link.title}
                  </Link>
                </>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
    </div>
  )
}