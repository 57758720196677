import React from 'react'
import { Container } from 'react-grid-system'
import { Social } from '../header/nav/links'

export default function Footer() {

    return (
        <footer 
            aria-labelledby="footerHeading" 
            className="my-20"
        >
            <Container
                data-sal="fade"
                data-sal-easing="ease"                    
                data-sal-duration="800"
            >
                <div className="text-center">
                    <h3 className="text-green-300">Let's build your wealth today.</h3>
                    <div className="flex justify-center icons-wrap my-10">
                        {Social.map((link, i) => (
                            <span key={i}>
                                <a href={link.href} title={link.name} target="_blank">
                                    {link.icon}
                                </a>
                            </span>
                        ))}
                    </div>
                    <p className="text-grey"><a href="https://www.google.com/maps/place/324+Laurier+Ave+W,+Ottawa,+ON+K1P+0A4/@45.4181958,-75.7015425,17z/data=!3m1!4b1!4m5!3m4!1s0x4cce0454a3679e3b:0x3d67a92a38213c21!8m2!3d45.4181921!4d-75.6993538" target="_blank">2114 - 324 Laurier Avenue W Ottawa ON K1P0A4</a><br/> 
                    <a href="mailto:contact@nbcassets.com?subject=Website Submission">contact@nbcassets.com</a></p>
                </div>
            </Container>
      </footer>
    )
}